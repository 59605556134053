import '@polkadot/api-augment/substrate';
import '../css/index.css';
import './bootstrap';

import * as Sentry from "@sentry/react";
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot, hydrateRoot } from 'react-dom/client';

const appName = import.meta.env.VITE_APP_NAME || 'PolkaIdentity';
const appEnv = import.meta.env.VITE_APP_ENV || 'local';
const dsn = import.meta.env.VITE_SENTRY_DSN;
const isLocal = appEnv === 'local';

createInertiaApp({
    title: (title) => `${appName} | Decentralized Identity in Polkadot`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob('./Pages/**/*.tsx'),
        ),
    setup({ el, App, props }) {
        Sentry.init({
            dsn: dsn,
            environment: appEnv,
            enabled: !isLocal,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            tracesSampleRate: isLocal ? 0 : import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
            replaysSessionSampleRate: isLocal ? 0 : import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE,
            replaysOnErrorSampleRate: isLocal ? 0 : import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE,
        });

        if (import.meta.env.SSR) {
            hydrateRoot(el, <App {...props} />);
            return;
        }

        createRoot(el).render(<App {...props} />);
    },
    progress: {
        color: '#4B5563',
    },
});
